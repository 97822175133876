@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.page {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 0 32px;
  }
}

.container {
  display: flex;
}

.content {
  flex: 1;
}

.form {
  margin: 0 auto;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 100%;
    margin-top: 55px;
    padding: 0 16px 60px 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 400px;
    margin-top: 100px;
    padding-bottom: 60px;
  }

  @media (min-width: $grid-breakpoint-large-min) {
    width: 450px;
  }
}

.actions {
  @media (max-width: $grid-breakpoint-small-max) {
    margin-bottom: 40px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-bottom: 65px;
  }
}

.title {
  @media (max-width: $grid-breakpoint-small-max) {
    margin-top: 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-top: 40px;
  }
}

.subtitle {
  @include font-defaults;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: $color-p-white;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-bottom: 20px;
    font-size: rem(14px);
    line-height: rem(14px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-bottom: 24px;
    font-size: rem(18px);
    line-height: rem(18px);
  }
}

.labelTerms {
  @include font-defaults;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
  color: $color-gray-80;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-top: 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-top: 24px;
  }

  a {
    font-weight: 500;
    color: $color-gray-80;
  }
}

.containerResetPassword {
  display: flex;
  justify-content: flex-end;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-top: 4px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-top: 10px;
  }
}

.buttonResetPassword {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.aside {
  width: 470px;
  display: flex;
  margin-right: 24px;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 85px);

  @media (max-width: 865px) {
    display: none;
  }

  @media (min-width: 866px) {
    width: 370px;
    padding-right: 40px;
  }

  @media (min-width: 1100px) {
    width: 470px;
    padding-right: 90px;
  }
}

.artistImageContainer {
  top: 0;
  left: 0;
  z-index: 0;
  width: 500px;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @media (min-width: 866px) {
    width: 400px;
  }

  @media (min-width: 1100px) {
    width: 500px;
  }
}

.artistImage {
  width: auto;
  height: 100%;
}

.artistDescription {
  @include font-defaults;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: $color-p-white;
  z-index: 1;
}

.artistQuote {
  z-index: 1;
  margin-top: 40px;
  padding-left: 15px;
  border-left: 1px solid $color-p-white;
}

.artistName {
  @include font-defaults;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: $color-p-white;
}

.artistLabel {
  @include font-defaults;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $color-p-white;
  margin-top: 12px;
}
